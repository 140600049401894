import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Typeform, Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 0";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "welcome-to-the-course"
    }}>{`Welcome to the course`}</h1>
    <p>{`Hi, welcome to the course.`}</p>
    <p>{`I recommend watching these intro videos right away then setting aside 20 to 30 minutes every day to work through the rest. The course is cut into 1 to 5 minute lessons so you have plenty of natural stopping points :)`}</p>
    <p>{`You can try binge watching, too, but I wouldn't recommend it.`}</p>
    <Vimeo id="388775067" mdxType="Vimeo" />
    <h3 {...{
      "id": "how-to-get-the-most-out-of-serverlessreactdev"
    }}>{`How to get the most out of ServerlessReact.Dev`}</h3>
    <Vimeo id="388779512" mdxType="Vimeo" />
    <h3 {...{
      "id": "goals-of-the-modern-stack"
    }}>{`Goals of the modern stack`}</h3>
    <Vimeo id="388885191" mdxType="Vimeo" />
    <h2 {...{
      "id": "why-did-you-buy-this-course"
    }}>{`Why did you buy this course?`}</h2>
    <Typeform url="https://swizecteller.typeform.com/to/faMda1" mdxType="Typeform" />
    <h2 {...{
      "id": "course-structure"
    }}>{`Course Structure`}</h2>
    <h3 {...{
      "id": "module-1---introduction-explains-the-modern-stack"
    }}>{`Module 1 - Introduction, explains the modern stack`}</h3>
    <p>{`In `}<a parentName="p" {...{
        "href": "/module-1"
      }}>{`Module 1`}</a>{` you will learn about the modern stack and some mental models about how it works. We discuss how to think about building a modern app, what to consider when deploying your infrastructure, and what each piece of the puzzle is doing for us.`}</p>
    <h3 {...{
      "id": "module-2---the-basics-shows-you-how-to-set-up-a-project"
    }}>{`Module 2 - The Basics, shows you how to set up a project`}</h3>
    <p>{`In `}<a parentName="p" {...{
        "href": "/module-2"
      }}>{`Module 2`}</a>{` you learn how to set up a modern webapp project. Configure basic AWS stuff, get a Gatsby rolling, set up a basic GraphQL server, and get started with a design system.`}</p>
    <p>{`You're going to see how we set up a new project from scratch using modern tools. After this module you're welcome to run off on your own and start building whatever you want.`}</p>
    <p>{`But I encourage you to stick around a little longer and see how it al fits together :)`}</p>
    <h3 {...{
      "id": "module-3-shows-you-how-its-built"
    }}>{`Module 3 shows you how it's built`}</h3>
    <p><a parentName="p" {...{
        "href": "/module-3"
      }}>{`Module-3`}</a>{`, you're going to watch me build an app from scratch, put everything together, and as we do so, I'm going to explain what everything does and why. This is going to be pretty heavy on the code-along approach so I recommend working on your own in parallel.`}</p>
    <p>{`These videos are great at 2x speed.`}</p>
    <h3 {...{
      "id": "module-4-shows-you-production-mode"
    }}>{`Module 4 shows you production mode`}</h3>
    <p>{`In `}<a parentName="p" {...{
        "href": "/module-4"
      }}>{`Module 4`}</a>{` you're going to watch me go to production with our example app. Set up a prod environment, add payments, re-deploy the app on user actions, and deal with prod vs. dev configuration.`}</p>
    <p>{`Just like Module 3, I recommend watching at 2x speed and building your own app as you go along.`}</p>
    <h2 {...{
      "id": "enjoy-️"
    }}>{`Enjoy ❤️`}</h2>
    <p>{`If you have questions, you can tweet me at @swizec or email at `}<a parentName="p" {...{
        "href": "mailto:swizec@swizec.com"
      }}>{`swizec@swizec.com`}</a>{` and I'll do my best to reply.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      